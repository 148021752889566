import {
  GetLatestEvidenceRequest,
  ListEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { ParsedQuery } from 'query-string'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'

export const applyEvidenceFilters = (
  req: ListEvidenceRequest | GetLatestEvidenceRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.CONTROL: {
      // for now, set only one control id
      req.setControlId(filterParams[0])
      break
    }
    case FilterParam.REQUEST: {
      // for now, set only one request id
      req.setDocumentRequestId(filterParams[0])
      break
    }

    default:
      break
  }
}

export const getControlIdForEvidence = (
  queryParams: ParsedQuery<string>,
): string => {
  const controlIds = queryParams[FilterParam.CONTROL]
  if (!controlIds || controlIds.length !== 1) {
    return ''
  }
  return controlIds[0]
}

export const isRelevantDateInAudit = (
  audit: AuditRecord,
  date?: Date,
): boolean => {
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  if (!date || !startDate || !endDate) {
    return false
  }
  return date >= startDate.toDate() && date <= endDate.toDate()
}
