import themeGet from '@styled-system/theme-get'
import { TypeButton } from 'src/components/ModalForms/FileType/TypeSelector.styles'
import { StandardButton } from 'src/components/Reusable/Buttons'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import styled from 'styled-components/macro'

export const AddFileButton = styled(TypeButton)`
  width: 100%;
  padding: 2.25em 0;
  gap: 0.75em;
`

export const ReplaceFileButton = styled(StandardButton)`
  &:hover {
    cursor: pointer;
  }
`

export const ClearFileButton = styled(TextButton)`
  margin-left: 0.5em;
`

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeGet('colors.border.neutral.light')};
  height: 240px;
  gap: 10px;

  svg {
    width 80px;
    height: 80px;
  }

  .filename {
    ${themeGet('textStyles.subtitle.1')};
    margin-bottom: ${themeGet('space.xxs')}px;
    line-height: 21px;
  }

  .size {
    ${themeGet('textStyles.subtitle.caption')}
    line-height: 18px;
  }

  &:hover {
    cursor: pointer;
  }
`
