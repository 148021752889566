import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'
import log from 'loglevel'
import { useNavigate, useParams } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { getServiceTemplate } from 'src/xgenerated/service'
import {
  DiscoveriesToMarkdown,
  ExclusionsToMarkdown,
  generateEvidencePdf,
  generatePdfBlob,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { getEvidenceSources } from 'src/Utils/Evidence/evidence.helpers'
import { Evidence } from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { Markup } from 'src/components/Reusable/Text/Markup'
import {
  ACTOR_TYPE,
  Document,
  ListEvidenceRequest,
  UpdateDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { isViewable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { AutomaticEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.components'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { isEvidenceFile } from 'src/components/ModalForms/Evidence/evidenceUtils'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { debounce, isFunction, isString } from 'lodash'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import { Tabs } from 'src/components/Reusable/Tabs'
import {
  useDocument,
  useFetchDocumentBody,
} from 'src/components/async/document/useDocument'
import { ImagePreview } from 'src/components/Reusable/Document/DocumentViewer.components'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { Spinner } from 'src/Throbber'
import { Gravatar } from 'src/components/Gravatar'
import {
  formatTimestamp,
  formatTimestampFromNumber,
} from 'src/Utils/formatDate'
import { showInfoToast, ToastPrompts } from 'src/Utils/helpers/toast'
import { MIME_TYPE, UrlFragments } from 'src/Utils/globalEnums'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useDownloadEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.hooks'
import {
  EvidenceTabContainer,
  FileDownloadBtn,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.styles'
import { PERMISSIONS } from 'src/config/roleConfig'
import FileSaver from 'file-saver'
import { DownloadIcon } from 'src/components/Icons'
import { getPluralization, openInNewTab } from 'src/Utils/globalHelpers'
import { EyeIcon } from 'src/components/Icons/Basic/EyeIcon'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useModalState } from 'src/Modal/ModalStateContext'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { ModalFormId } from 'src/components/ModalForms'
import { useEvidenceResults } from 'src/components/async/suggestions'
import { SelectDropdown } from 'src/components/Reusable/SelectDropdown/SelectDropdown'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useInAudit } from 'src/context/AuditContext'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  ItemTitle,
  ShowPageSectionHeader,
} from 'src/components/PageLayout/ShowPage'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { linkWithSearch } from 'src/components/PageLayout/Permalink'
import { EditableShowPageTitle } from 'src/components/Reusable/ShowPage/Title/EditableShowPageTitle'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import { TooltipOverlayType } from 'src/components/Reusable/Tooltip/Tooltip'
import { TrusteroDateRange } from 'src/components/Reusable/TrusteroDateRange'
import { useInvalidateAudits } from 'src/components/async/model/audit/useAudit'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import {
  EvidenceDropdownContainer,
  EvidenceFileInfoContainer,
  EvidenceFileName,
  EvidenceShowPageIcon,
  EvidenceShowTabBody,
  ManualEvidenceContainer,
  PropertiesGrid,
  PropertiesLabel,
  PropertiesRow,
  PropertiesValue,
  RelevantDateButton,
} from './evidence.styles'
import { useEvidenceAudits } from './evidence.hooks'
import { ShowEvidenceAuditsModal } from './modals/ShowEvidenceAuditsModal'

export const AutomatedEvidenceIcon = (): JSX.Element => {
  return <EvidenceShowPageIcon icon={faWandMagicSparkles} />
}

export const EvidenceAuditsRow = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceAudits(document.getId())
  const { openModal } = useModalState()
  if (isLoading) {
    return <Spinner size="l" color="primary" />
  } else if (!data) {
    if (error) {
      log.error('Error loading evidence audits', error)
    }
    return <></>
  }

  const audits = data.getItemsList()

  return (
    <>
      <PropertiesRow>
        <PropertiesLabel>Audits</PropertiesLabel>
        <PropertiesValue>
          <FlexRow gap={5}>
            <EyeIcon />
            <TextButton onClick={() => openModal(ModalFormId.EVIDENCE_AUDITS)}>
              {`${audits.length} ${getPluralization('audit', audits.length)}`}
            </TextButton>
          </FlexRow>
        </PropertiesValue>
      </PropertiesRow>
      <ShowEvidenceAuditsModal audits={audits} />
    </>
  )
}

export const EvidenceAddedByRow = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const actorType = document.getActortype()
  const actor = document.getActor()
  const isAutomated = actorType !== ACTOR_TYPE.USER

  return (
    <PropertiesRow>
      <PropertiesLabel>Added By</PropertiesLabel>
      <PropertiesValue>
        {isAutomated ? (
          <FlexRow gap={5}>
            <AutomatedEvidenceIcon />
            Receptor
          </FlexRow>
        ) : (
          <Gravatar email={actor} size={20} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidenceRelevantDateComponent = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const mutate = useInvalidateAudits()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const relevantDate = useMemo(() => {
    const relevantDateSeconds = document.getRelevantdate()?.getSeconds() || 0
    return new Date(relevantDateSeconds * 1000)
  }, [document])
  const [relevantDateAsRange, setRelevantDateAsRange] = useState<Range>({
    startDate: new Date(),
    endDate: relevantDate,
  })

  const updateRelevantDate = useMemo(() => {
    return debounce(async () => {
      try {
        const updateRequest = new UpdateDocumentRequest()
          .setId(document.getId())
          .setRelevantDate(
            Timestamp.fromDate(relevantDateAsRange.endDate as Date),
          )

        await attachmentClient.updateDocument(updateRequest)
        await mutate()
      } catch (e) {
        log.error(`Error updating relevant date`, e)
        showInfoToast(
          'Sorry, there was an error saving your changes. Please try again later.',
        )
      }
    }, 1000)
  }, [document, relevantDateAsRange, attachmentClient, mutate])

  useEffect(() => {
    if (relevantDate && relevantDate !== relevantDateAsRange.endDate) {
      updateRelevantDate()
    }
  }, [relevantDate, relevantDateAsRange, updateRelevantDate])

  return (
    <TrusteroDateRange
      single
      range={relevantDateAsRange}
      rangeSet={setRelevantDateAsRange}
    />
  )
}

export const EvidenceRelevantDateRow = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const hasRelevantDateForm = IsGatekeeperEnabled(
    GatekeeperFlag.RELEVANT_DATE_FORM,
  )
  const relevantDate = document.getRelevantdate()

  if (!relevantDate) return <></>

  return (
    <PropertiesRow>
      <PropertiesLabel>
        <FlexRow gap={8}>
          Relevant Date
          <Tooltip
            id="relevant-date-tooltip"
            overlayType={TooltipOverlayType.popover}
            tooltipBody="Relevant date that the evidence addresses. If your evidence covers a date range, use the end date."
          >
            <InfoButton />
          </Tooltip>
        </FlexRow>
      </PropertiesLabel>
      <PropertiesValue>
        {hasRelevantDateForm ? (
          <EvidenceRelevantDateButton
            evidenceId={document.getId()}
            relevantDate={relevantDate}
          />
        ) : (
          <EvidenceRelevantDateComponent document={document} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidencePropertiesBody = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const isManual = document.getActortype() === ACTOR_TYPE.USER
  const createdAt = document.getCreatedat()
  const createdAtVerb = isManual ? 'Uploaded' : 'Created'

  return (
    <EvidenceShowTabBody>
      <PropertiesGrid>
        <EvidenceAddedByRow document={document} />
        <EvidenceAuditsRow document={document} />
        {isManual && <EvidenceRelevantDateRow document={document} />}
        <PropertiesRow>
          <PropertiesLabel>{`${createdAtVerb} Date`}</PropertiesLabel>
          <PropertiesValue>
            {formatTimestampFromNumber(createdAt)}
          </PropertiesValue>
        </PropertiesRow>
      </PropertiesGrid>
    </EvidenceShowTabBody>
  )
}

export const AutomaticEvidenceBody = ({
  body,
}: {
  body?: Uint8Array
}): JSX.Element => {
  if (!body) return <></>
  const evidence: Evidence = Evidence.deserializeBinary(body)
  return <AutomaticEvidenceTable evidence={evidence} />
}

export const EvidenceDocumentFooter = ({
  document,
  body,
}: {
  document: Document
  body: string | Uint8Array | null
}): JSX.Element => {
  const contentId = document.getContentid()
  const mime = document.getMime()
  const downloadEvidenceTable = useDownloadEvidenceTable({
    contentId,
    mime,
  })
  const isLink = mime === MIME_TYPE.TEXT_URI_LIST
  const caption = document.getCaption()
  const isAutomated = document.getActortype() !== ACTOR_TYPE.USER

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mime)
      FileSaver.saveAs(material)
    }
  }

  const downloadEvidencePdf = async (): Promise<void> => {
    if (!body) return
    const pdfContent = await generateEvidencePdf(document, body, caption)
    const pdfBlob = await generatePdfBlob(pdfContent)
    FileSaver.saveAs(pdfBlob, `${caption}.pdf`)
  }

  return (
    <FlexRow justify={FlexAlign.FLEX_START} $mt={10} gap={16}>
      {!isAutomated ? (
        <>
          {isLink ? (
            <></>
          ) : (
            <FileDownloadBtn
              onClick={downloadFile}
              requiredPermissions={[PERMISSIONS.EXPORT]}
            >
              <DownloadIcon height={14} width={14} fontSize={14} />
              <span>Download File</span>
            </FileDownloadBtn>
          )}
        </>
      ) : (
        <>
          <FileDownloadBtn
            onClick={downloadEvidenceTable}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download Table</span>
          </FileDownloadBtn>
          <FileDownloadBtn
            onClick={downloadEvidencePdf}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download All Evidence</span>
          </FileDownloadBtn>
        </>
      )}
    </FlexRow>
  )
}

export const EvidenceBody = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const contentId = document.getContentid()
  const mime = document.getMime()
  const [file, setFile] = useState<File | null>(null)
  const [manualBody, setManualBody] = useState<string | Uint8Array | null>(null)
  const [automaticBody, setAutomaticBody] = useState<Uint8Array | null>(null)
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const isFile = isEvidenceFile(mime)

  const isAutomated = document.getActortype() !== ACTOR_TYPE.USER

  useEffect(() => {
    if (!document) return
    if (isFunction(fetchDocumentBody)) {
      const handleDocumentBody = async () => {
        try {
          const documentBody = await fetchDocumentBody()
          if (documentBody) {
            if (isAutomated && !isString(documentBody)) {
              setAutomaticBody(documentBody)
            } else {
              setManualBody(documentBody)
            }
          }
        } catch (err) {
          log.error(
            `Error downloading document in EvidenceTabs - id: ${document.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
      }
      handleDocumentBody()
    }

    return () => {
      setManualBody(null)
      setAutomaticBody(null)
    }
  }, [document, fetchDocumentBody, isAutomated])

  useEffect(() => {
    const caption = document.getCaption()
    const mimeType = document.getMime()
    if (manualBody) {
      const file = documentBodyAsFile(manualBody, caption, mimeType)
      if (isViewable(file.type)) {
        setFile(file)
      }
    }
  }, [manualBody, document, setFile])

  const showAutomated = isAutomated && automaticBody

  return (
    <>
      {automaticBody || manualBody ? (
        <>
          {showAutomated ? (
            <AutomaticEvidenceBody body={automaticBody} />
          ) : (
            <>
              {isFile && (
                // avoid allowing file preview click if not ready/available
                <>{file ? <ImagePreview file={file} /> : <ContentLoader />}</>
              )}
            </>
          )}
          <EvidenceDocumentFooter
            document={document}
            body={isAutomated ? automaticBody : manualBody}
          />
        </>
      ) : (
        <ContentLoader />
      )}
    </>
  )
}

export const EvidenceExcludedBody = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const getServiceName = useCallback(
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
    [],
  )

  const exclusionBody = useMemo(() => {
    const exclusions = document.getAllexclusions()?.getExclusionsList() ?? []
    return ExclusionsToMarkdown(exclusions, getServiceName)
  }, [document, getServiceName])

  return (
    <EvidenceShowTabBody>
      <Markup markdown={exclusionBody} />
    </EvidenceShowTabBody>
  )
}

export const EvidenceSourceBody = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const [body, setBody] = useState<Uint8Array | null>(null)
  const fetchDocumentBody = useFetchDocumentBody(
    document.getContentid(),
    document.getMime(),
  )

  useEffect(() => {
    if (!document) return
    if (isFunction(fetchDocumentBody)) {
      const handleDocumentBody = async () => {
        try {
          const documentBody = await fetchDocumentBody()
          if (documentBody && documentBody instanceof Uint8Array) {
            setBody(documentBody)
          }
        } catch (err) {
          log.error(
            `Error downloading document in EvidenceTabs - id: ${document.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
      }
      handleDocumentBody()
    }
  }, [document, fetchDocumentBody])

  const evidence: Evidence =
    body instanceof Uint8Array
      ? Evidence.deserializeBinary(body)
      : new Evidence()
  const evidenceSources = getEvidenceSources(evidence)
  const sourcesMarkdown = DiscoveriesToMarkdown(evidenceSources)
  return (
    <EvidenceShowTabBody>
      <Markup markdown={sourcesMarkdown} />
    </EvidenceShowTabBody>
  )
}

export const EvidenceDocumentSection = ({
  evidenceId,
}: {
  evidenceId: string
}): JSX.Element => {
  const {
    data: document,
    isLoading: docIsLoading,
    error: docError,
  } = useDocument({
    documentId: evidenceId,
  })
  if (docIsLoading) {
    return <ContentLoader />
  } else if (!document) {
    if (docError) {
      log.error('Error loading document', docError)
    }
    return <></>
  }

  const isAutomated = document.getActortype() !== ACTOR_TYPE.USER
  const mime = document.getMime()

  const showEvidenceAboveFold = !isAutomated && !isViewable(mime)

  return (
    <>
      {showEvidenceAboveFold && <ManualEvidenceSection document={document} />}
      <EvidenceTabs
        document={document}
        includeEvidence={!showEvidenceAboveFold}
      />
    </>
  )
}

export const EvidenceTabs = ({
  document,
  includeEvidence = true,
}: {
  document: Document
  includeEvidence?: boolean
}): JSX.Element => {
  const isAutomated = document.getActortype() !== ACTOR_TYPE.USER

  const evidenceTab = {
    title: 'Evidence',
    body: <EvidenceBody document={document} />,
  }

  const excludedTab = {
    title: 'Excluded',
    body: <EvidenceExcludedBody document={document} />,
  }

  const sourceTab = {
    title: 'Source',
    body: <EvidenceSourceBody document={document} />,
  }

  const propertiesTab = {
    title: 'Properties',
    body: <EvidencePropertiesBody document={document} />,
  }

  const tabs = [propertiesTab]

  if (includeEvidence) {
    tabs.unshift(evidenceTab)
  }

  if (isAutomated) {
    tabs.splice(1, 0, excludedTab)
    tabs.splice(2, 0, sourceTab)
  }

  return (
    <EvidenceTabContainer>
      <Tabs tabs={tabs} />
    </EvidenceTabContainer>
  )
}

export const EvidenceAuditRowItem = ({
  audit,
}: {
  audit: AuditRecord
}): JSX.Element => {
  const auditName = audit.getName()
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  return (
    <li>
      {auditName}. {startDate ? formatTimestamp(startDate) : ''}
      {endDate ? `-${formatTimestamp(endDate)}` : ''}
    </li>
  )
}

export const EvidenceVersionDropdown = ({
  caption,
  creationDate,
  controlId,
  requestId,
  fromControl,
}: {
  caption: string
  creationDate: Timestamp
  controlId?: string
  requestId?: string
  fromControl?: boolean
}): JSX.Element => {
  const { auditId } = useInAudit()
  const { pageContext } = useParams()
  const navigate = useNavigate()
  const request = useMemo(() => {
    const req = new ListEvidenceRequest().setCaption(
      new StringValue().setValue(caption),
    )
    controlId && req.setControlId(controlId)
    requestId && req.setDocumentRequestId(requestId)
    auditId && req.setAuditId(new StringValue().setValue(auditId))
    return req
  }, [caption, controlId, requestId, auditId])
  const { data, isLoading, error } = useEvidenceResults(request)

  const creationDateString = formatTimestamp(
    creationDate,
    DATE_FORMATS.WITH_TIME,
  )
  if (isLoading) {
    return <Spinner size="l" color="primary" />
  } else if (!data) {
    if (error) {
      log.error('Error loading evidence versions', error)
    }
    return <></>
  }
  const items = data
    ?.getItemsList()
    .sort((a, b) => {
      const aCreatedAt = a.getCreatedAt()
      const bCreatedAt = b.getCreatedAt()
      if (!aCreatedAt || !bCreatedAt) return 0
      return bCreatedAt.toDate().getTime() - aCreatedAt.toDate().getTime()
    })
    .map((evidence) => ({
      name: formatTimestamp(
        evidence.getCreatedAt() || new Timestamp(),
        DATE_FORMATS.WITH_TIME,
      ),
      value: evidence.getId(),
    })) as SelectItem[]

  const onSelectCb = (values: SelectItem[]): void => {
    const selectedId = values[0]?.value
    const versionLink = linkWithSearch(
      `/${pageContext}/${EvidenceAbsoluteRoutes.SHOW}/${selectedId}`,
    )
    const fragment = fromControl ? `#${UrlFragments.CONTROLS}` : ''
    navigate(`${versionLink}${fragment}`)
  }

  return (
    <EvidenceDropdownContainer>
      <SelectDropdown
        label=""
        placeholder={creationDateString}
        items={items}
        onSelectCb={onSelectCb}
        isDisabled={items.length < 2}
      />
    </EvidenceDropdownContainer>
  )
}

export const ManualEvidenceBody = ({
  body,
  isLink,
}: {
  body: string
  isLink: boolean
}): JSX.Element =>
  isLink ? (
    <TextButton
      onClick={(e) => {
        e.preventDefault()
        openInNewTab(body.trim())
      }}
    >
      {body.trim()}
    </TextButton>
  ) : (
    <Markup markdown={body} />
  )

export const EvidenceShowTitle = ({
  id,
  caption,
  isAutomated,
}: {
  id: string
  caption: string
  isAutomated: boolean
}): JSX.Element => {
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const mutate = useHardEvidenceInvalidation()
  const onUpdate = async (newCaption: string): Promise<void> => {
    const updateRequest = new UpdateDocumentRequest()
      .setCaption(new StringValue().setValue(newCaption))
      .setId(id)
    await attachmentClient.updateDocument(updateRequest)
    await mutate()
  }

  return isAutomated ? (
    <ItemTitle>{caption}</ItemTitle>
  ) : (
    <EditableShowPageTitle
      id={id}
      name={caption}
      onUpdate={onUpdate}
      modelType={MODEL_TYPE.EVIDENCE}
      fieldName="caption"
    />
  )
}

export const ManualEvidenceSection = ({
  document,
}: {
  document: Document
}): JSX.Element => {
  const contentId = document.getContentid()
  const mime = document.getMime()
  const [body, setBody] = useState<string | Uint8Array | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const isFile = isEvidenceFile(mime)

  const FileTypeIcon = useFileTypeIcon({ mime })
  const caption = document.getCaption()

  useEffect(() => {
    if (!document) return
    const caption = document.getCaption()
    const mime = document.getMime()
    const isFile = isEvidenceFile(mime)
    if (isFunction(fetchDocumentBody)) {
      const handleDocumentBody = async () => {
        try {
          const documentBody = await fetchDocumentBody()
          if (documentBody) {
            setBody(documentBody)
            if (isFile) {
              const file = documentBodyAsFile(documentBody, caption, mime)
              setFile(file)
            }
          }
        } catch (err) {
          log.error(
            `Error downloading document in EvidenceTabs - id: ${document.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
      }
      handleDocumentBody()
    }

    return () => {
      setBody(null)
    }
  }, [document, fetchDocumentBody])

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mime)
      FileSaver.saveAs(material)
    }
  }
  const isLink = mime === MIME_TYPE.TEXT_URI_LIST

  return (
    <>
      {body ? (
        <ManualEvidenceContainer>
          <ShowPageSectionHeader> EVIDENCE </ShowPageSectionHeader>
          {isFile ? (
            <EvidenceFileInfoContainer onClick={downloadFile}>
              {file ? (
                <>
                  <FileTypeIcon />
                  <EvidenceFileName>{file.name}</EvidenceFileName>
                </>
              ) : (
                <Spinner size="m" color="primary" />
              )}
            </EvidenceFileInfoContainer>
          ) : (
            <ManualEvidenceBody body={body as string} isLink={isLink} />
          )}
          <EvidenceDocumentFooter document={document} body={body} />
        </ManualEvidenceContainer>
      ) : (
        <ContentLoader />
      )}
    </>
  )
}

export const EvidenceRelevantDateButton = ({
  evidenceId,
  relevantDate,
}: {
  evidenceId: string
  relevantDate: Timestamp
}): JSX.Element => {
  const { openModal } = useModalState()
  const { setEvidenceId } = useCurrentEvidenceId()

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    setEvidenceId(evidenceId)
    openModal(ModalFormId.RELEVANT_DATE)
  }

  return (
    <RelevantDateButton
      buttonSize={StandardButtonSize.SMALL}
      variant={StandardButtonVariant.SECONDARY}
      onClick={onClick}
    >
      {formatTimestamp(relevantDate, DATE_FORMATS.ISO)}
    </RelevantDateButton>
  )
}
