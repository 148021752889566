import { useMemo } from 'react'
import {
  GetLatestEvidenceRequest,
  ListEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { useLocation } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import {
  AuditRecords,
  EvidenceFilter,
  GetAuditsRequest,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { applyEvidenceFilters } from './evidence.helpers'

export const useLatestEvidenceRequest = (): GetLatestEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new GetLatestEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceRequest = (): ListEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceAudits = (
  evidenceId: string,
): GrpcResponse<AuditRecords> => {
  const req = useMemo(() => {
    const req = new GetAuditsRequest()
    req
      .setEvidenceFilter(new EvidenceFilter().setEvidenceId(evidenceId))
      .setIsClosed(new BoolValue().setValue(false))
    return req
  }, [evidenceId])

  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getAudits,
    req,
    true,
  )
  return NewGrpcResponse(response)
}
