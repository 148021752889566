// source: audit/audit.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var audit_framework_pb = require('../audit/framework_pb.js');
goog.object.extend(proto, audit_framework_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.audit.AddControlsRequest', null, global);
goog.exportSymbol('proto.audit.AuditRecord', null, global);
goog.exportSymbol('proto.audit.AuditRecords', null, global);
goog.exportSymbol('proto.audit.CloseAuditRequest', null, global);
goog.exportSymbol('proto.audit.ControlsFilter', null, global);
goog.exportSymbol('proto.audit.CriteriaControlMapping', null, global);
goog.exportSymbol('proto.audit.DeleteAuditRequest', null, global);
goog.exportSymbol('proto.audit.EvidenceFilter', null, global);
goog.exportSymbol('proto.audit.GetAuditRequest', null, global);
goog.exportSymbol('proto.audit.GetAuditsRequest', null, global);
goog.exportSymbol('proto.audit.GetComplianceFrameworksRequest', null, global);
goog.exportSymbol('proto.audit.GetCriteriaControlMappingsRequest', null, global);
goog.exportSymbol('proto.audit.GetCriteriaControlMappingsResponse', null, global);
goog.exportSymbol('proto.audit.GetHasComplianceFrameworksResponse', null, global);
goog.exportSymbol('proto.audit.GetReportRequest', null, global);
goog.exportSymbol('proto.audit.GetReportResponse', null, global);
goog.exportSymbol('proto.audit.RelevantDateFilter', null, global);
goog.exportSymbol('proto.audit.RemoveControlsFromAllAuditsRequest', null, global);
goog.exportSymbol('proto.audit.RemoveControlsRequest', null, global);
goog.exportSymbol('proto.audit.Report', null, global);
goog.exportSymbol('proto.audit.ReportType', null, global);
goog.exportSymbol('proto.audit.SaveAuditRequest', null, global);
goog.exportSymbol('proto.audit.SaveAuditResponse', null, global);
goog.exportSymbol('proto.audit.SaveReportRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.RemoveControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.RemoveControlsRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.RemoveControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.RemoveControlsRequest.displayName = 'proto.audit.RemoveControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.RemoveControlsFromAllAuditsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.RemoveControlsFromAllAuditsRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.RemoveControlsFromAllAuditsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.RemoveControlsFromAllAuditsRequest.displayName = 'proto.audit.RemoveControlsFromAllAuditsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AddControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AddControlsRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.AddControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AddControlsRequest.displayName = 'proto.audit.AddControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.DeleteAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.DeleteAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.DeleteAuditRequest.displayName = 'proto.audit.DeleteAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditRequest.displayName = 'proto.audit.GetAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.EvidenceFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.EvidenceFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.EvidenceFilter.displayName = 'proto.audit.EvidenceFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ControlsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.ControlsFilter.repeatedFields_, null);
};
goog.inherits(proto.audit.ControlsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ControlsFilter.displayName = 'proto.audit.ControlsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.RelevantDateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.RelevantDateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.RelevantDateFilter.displayName = 'proto.audit.RelevantDateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAuditsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditsRequest.displayName = 'proto.audit.GetAuditsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.SaveAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.SaveAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.SaveAuditRequest.displayName = 'proto.audit.SaveAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.SaveAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.SaveAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.SaveAuditResponse.displayName = 'proto.audit.SaveAuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRecords = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditRecords.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditRecords, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRecords.displayName = 'proto.audit.AuditRecords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetReportRequest.displayName = 'proto.audit.GetReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.SaveReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.SaveReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.SaveReportRequest.displayName = 'proto.audit.SaveReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetReportResponse.displayName = 'proto.audit.GetReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.CloseAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.CloseAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.CloseAuditRequest.displayName = 'proto.audit.CloseAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditRecord.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRecord.displayName = 'proto.audit.AuditRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.Report.displayName = 'proto.audit.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetComplianceFrameworksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetComplianceFrameworksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetComplianceFrameworksRequest.displayName = 'proto.audit.GetComplianceFrameworksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetHasComplianceFrameworksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetHasComplianceFrameworksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetHasComplianceFrameworksResponse.displayName = 'proto.audit.GetHasComplianceFrameworksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetCriteriaControlMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetCriteriaControlMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetCriteriaControlMappingsRequest.displayName = 'proto.audit.GetCriteriaControlMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.CriteriaControlMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.CriteriaControlMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.CriteriaControlMapping.displayName = 'proto.audit.CriteriaControlMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetCriteriaControlMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetCriteriaControlMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.audit.GetCriteriaControlMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetCriteriaControlMappingsResponse.displayName = 'proto.audit.GetCriteriaControlMappingsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.RemoveControlsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.RemoveControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.RemoveControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.RemoveControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RemoveControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    noLogs: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.RemoveControlsRequest}
 */
proto.audit.RemoveControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.RemoveControlsRequest;
  return proto.audit.RemoveControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.RemoveControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.RemoveControlsRequest}
 */
proto.audit.RemoveControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.RemoveControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.RemoveControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.RemoveControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RemoveControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getNoLogs();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.RemoveControlsRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.RemoveControlsRequest} returns this
 */
proto.audit.RemoveControlsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string control_ids = 2;
 * @return {!Array<string>}
 */
proto.audit.RemoveControlsRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.RemoveControlsRequest} returns this
 */
proto.audit.RemoveControlsRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.RemoveControlsRequest} returns this
 */
proto.audit.RemoveControlsRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.RemoveControlsRequest} returns this
 */
proto.audit.RemoveControlsRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * optional bool no_logs = 3;
 * @return {boolean}
 */
proto.audit.RemoveControlsRequest.prototype.getNoLogs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RemoveControlsRequest} returns this
 */
proto.audit.RemoveControlsRequest.prototype.setNoLogs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.RemoveControlsFromAllAuditsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.RemoveControlsFromAllAuditsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RemoveControlsFromAllAuditsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlsIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.RemoveControlsFromAllAuditsRequest}
 */
proto.audit.RemoveControlsFromAllAuditsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.RemoveControlsFromAllAuditsRequest;
  return proto.audit.RemoveControlsFromAllAuditsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.RemoveControlsFromAllAuditsRequest}
 */
proto.audit.RemoveControlsFromAllAuditsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlsIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.RemoveControlsFromAllAuditsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RemoveControlsFromAllAuditsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string controls_ids = 1;
 * @return {!Array<string>}
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.getControlsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.RemoveControlsFromAllAuditsRequest} returns this
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.setControlsIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.RemoveControlsFromAllAuditsRequest} returns this
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.addControlsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.RemoveControlsFromAllAuditsRequest} returns this
 */
proto.audit.RemoveControlsFromAllAuditsRequest.prototype.clearControlsIdsList = function() {
  return this.setControlsIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AddControlsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AddControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AddControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AddControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AddControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AddControlsRequest}
 */
proto.audit.AddControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AddControlsRequest;
  return proto.audit.AddControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AddControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AddControlsRequest}
 */
proto.audit.AddControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AddControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AddControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AddControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AddControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string audit_ids = 1;
 * @return {!Array<string>}
 */
proto.audit.AddControlsRequest.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};


/**
 * repeated string control_ids = 2;
 * @return {!Array<string>}
 */
proto.audit.AddControlsRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AddControlsRequest} returns this
 */
proto.audit.AddControlsRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.DeleteAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.DeleteAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.DeleteAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.DeleteAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.DeleteAuditRequest}
 */
proto.audit.DeleteAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.DeleteAuditRequest;
  return proto.audit.DeleteAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.DeleteAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.DeleteAuditRequest}
 */
proto.audit.DeleteAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.DeleteAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.DeleteAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.DeleteAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.DeleteAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.DeleteAuditRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.DeleteAuditRequest} returns this
 */
proto.audit.DeleteAuditRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditRequest}
 */
proto.audit.GetAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditRequest;
  return proto.audit.GetAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditRequest}
 */
proto.audit.GetAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.GetAuditRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditRequest} returns this
 */
proto.audit.GetAuditRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.EvidenceFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.EvidenceFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.EvidenceFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.EvidenceFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.EvidenceFilter}
 */
proto.audit.EvidenceFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.EvidenceFilter;
  return proto.audit.EvidenceFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.EvidenceFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.EvidenceFilter}
 */
proto.audit.EvidenceFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.EvidenceFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.EvidenceFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.EvidenceFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.EvidenceFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string evidence_id = 1;
 * @return {string}
 */
proto.audit.EvidenceFilter.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.EvidenceFilter} returns this
 */
proto.audit.EvidenceFilter.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.ControlsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ControlsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ControlsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ControlsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ControlsFilter}
 */
proto.audit.ControlsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ControlsFilter;
  return proto.audit.ControlsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ControlsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ControlsFilter}
 */
proto.audit.ControlsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ControlsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ControlsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ControlsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string control_ids = 1;
 * @return {!Array<string>}
 */
proto.audit.ControlsFilter.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.ControlsFilter} returns this
 */
proto.audit.ControlsFilter.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.ControlsFilter} returns this
 */
proto.audit.ControlsFilter.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.ControlsFilter} returns this
 */
proto.audit.ControlsFilter.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.RelevantDateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.RelevantDateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.RelevantDateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RelevantDateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.RelevantDateFilter}
 */
proto.audit.RelevantDateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.RelevantDateFilter;
  return proto.audit.RelevantDateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.RelevantDateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.RelevantDateFilter}
 */
proto.audit.RelevantDateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.RelevantDateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.RelevantDateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.RelevantDateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RelevantDateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp relevant_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.RelevantDateFilter.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.RelevantDateFilter} returns this
*/
proto.audit.RelevantDateFilter.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.RelevantDateFilter} returns this
 */
proto.audit.RelevantDateFilter.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.RelevantDateFilter.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isClosed: (f = msg.getIsClosed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    includeDeleted: (f = msg.getIncludeDeleted()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    evidenceFilter: (f = msg.getEvidenceFilter()) && proto.audit.EvidenceFilter.toObject(includeInstance, f),
    controlsFilter: (f = msg.getControlsFilter()) && proto.audit.ControlsFilter.toObject(includeInstance, f),
    relevantDateFilter: (f = msg.getRelevantDateFilter()) && proto.audit.RelevantDateFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditsRequest}
 */
proto.audit.GetAuditsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditsRequest;
  return proto.audit.GetAuditsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditsRequest}
 */
proto.audit.GetAuditsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsClosed(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeDeleted(value);
      break;
    case 3:
      var value = new proto.audit.EvidenceFilter;
      reader.readMessage(value,proto.audit.EvidenceFilter.deserializeBinaryFromReader);
      msg.setEvidenceFilter(value);
      break;
    case 4:
      var value = new proto.audit.ControlsFilter;
      reader.readMessage(value,proto.audit.ControlsFilter.deserializeBinaryFromReader);
      msg.setControlsFilter(value);
      break;
    case 5:
      var value = new proto.audit.RelevantDateFilter;
      reader.readMessage(value,proto.audit.RelevantDateFilter.deserializeBinaryFromReader);
      msg.setRelevantDateFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsClosed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIncludeDeleted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEvidenceFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audit.EvidenceFilter.serializeBinaryToWriter
    );
  }
  f = message.getControlsFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audit.ControlsFilter.serializeBinaryToWriter
    );
  }
  f = message.getRelevantDateFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audit.RelevantDateFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_closed = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.audit.GetAuditsRequest.prototype.getIsClosed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.audit.GetAuditsRequest} returns this
*/
proto.audit.GetAuditsRequest.prototype.setIsClosed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditsRequest} returns this
 */
proto.audit.GetAuditsRequest.prototype.clearIsClosed = function() {
  return this.setIsClosed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditsRequest.prototype.hasIsClosed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue include_deleted = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.audit.GetAuditsRequest.prototype.getIncludeDeleted = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.audit.GetAuditsRequest} returns this
*/
proto.audit.GetAuditsRequest.prototype.setIncludeDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditsRequest} returns this
 */
proto.audit.GetAuditsRequest.prototype.clearIncludeDeleted = function() {
  return this.setIncludeDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditsRequest.prototype.hasIncludeDeleted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EvidenceFilter evidence_filter = 3;
 * @return {?proto.audit.EvidenceFilter}
 */
proto.audit.GetAuditsRequest.prototype.getEvidenceFilter = function() {
  return /** @type{?proto.audit.EvidenceFilter} */ (
    jspb.Message.getWrapperField(this, proto.audit.EvidenceFilter, 3));
};


/**
 * @param {?proto.audit.EvidenceFilter|undefined} value
 * @return {!proto.audit.GetAuditsRequest} returns this
*/
proto.audit.GetAuditsRequest.prototype.setEvidenceFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditsRequest} returns this
 */
proto.audit.GetAuditsRequest.prototype.clearEvidenceFilter = function() {
  return this.setEvidenceFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditsRequest.prototype.hasEvidenceFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ControlsFilter controls_filter = 4;
 * @return {?proto.audit.ControlsFilter}
 */
proto.audit.GetAuditsRequest.prototype.getControlsFilter = function() {
  return /** @type{?proto.audit.ControlsFilter} */ (
    jspb.Message.getWrapperField(this, proto.audit.ControlsFilter, 4));
};


/**
 * @param {?proto.audit.ControlsFilter|undefined} value
 * @return {!proto.audit.GetAuditsRequest} returns this
*/
proto.audit.GetAuditsRequest.prototype.setControlsFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditsRequest} returns this
 */
proto.audit.GetAuditsRequest.prototype.clearControlsFilter = function() {
  return this.setControlsFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditsRequest.prototype.hasControlsFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RelevantDateFilter relevant_date_filter = 5;
 * @return {?proto.audit.RelevantDateFilter}
 */
proto.audit.GetAuditsRequest.prototype.getRelevantDateFilter = function() {
  return /** @type{?proto.audit.RelevantDateFilter} */ (
    jspb.Message.getWrapperField(this, proto.audit.RelevantDateFilter, 5));
};


/**
 * @param {?proto.audit.RelevantDateFilter|undefined} value
 * @return {!proto.audit.GetAuditsRequest} returns this
*/
proto.audit.GetAuditsRequest.prototype.setRelevantDateFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditsRequest} returns this
 */
proto.audit.GetAuditsRequest.prototype.clearRelevantDateFilter = function() {
  return this.setRelevantDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditsRequest.prototype.hasRelevantDateFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.SaveAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.SaveAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.SaveAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    audit: (f = msg.getAudit()) && proto.audit.AuditRecord.toObject(includeInstance, f),
    includeControls: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.SaveAuditRequest}
 */
proto.audit.SaveAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.SaveAuditRequest;
  return proto.audit.SaveAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.SaveAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.SaveAuditRequest}
 */
proto.audit.SaveAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.AuditRecord;
      reader.readMessage(value,proto.audit.AuditRecord.deserializeBinaryFromReader);
      msg.setAudit(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.SaveAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.SaveAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.SaveAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audit.AuditRecord.serializeBinaryToWriter
    );
  }
  f = message.getIncludeControls();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional AuditRecord audit = 1;
 * @return {?proto.audit.AuditRecord}
 */
proto.audit.SaveAuditRequest.prototype.getAudit = function() {
  return /** @type{?proto.audit.AuditRecord} */ (
    jspb.Message.getWrapperField(this, proto.audit.AuditRecord, 1));
};


/**
 * @param {?proto.audit.AuditRecord|undefined} value
 * @return {!proto.audit.SaveAuditRequest} returns this
*/
proto.audit.SaveAuditRequest.prototype.setAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.SaveAuditRequest} returns this
 */
proto.audit.SaveAuditRequest.prototype.clearAudit = function() {
  return this.setAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.SaveAuditRequest.prototype.hasAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool include_controls = 2;
 * @return {boolean}
 */
proto.audit.SaveAuditRequest.prototype.getIncludeControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.SaveAuditRequest} returns this
 */
proto.audit.SaveAuditRequest.prototype.setIncludeControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.SaveAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.SaveAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.SaveAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    audit: (f = msg.getAudit()) && proto.audit.AuditRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.SaveAuditResponse}
 */
proto.audit.SaveAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.SaveAuditResponse;
  return proto.audit.SaveAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.SaveAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.SaveAuditResponse}
 */
proto.audit.SaveAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.AuditRecord;
      reader.readMessage(value,proto.audit.AuditRecord.deserializeBinaryFromReader);
      msg.setAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.SaveAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.SaveAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.SaveAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audit.AuditRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuditRecord audit = 1;
 * @return {?proto.audit.AuditRecord}
 */
proto.audit.SaveAuditResponse.prototype.getAudit = function() {
  return /** @type{?proto.audit.AuditRecord} */ (
    jspb.Message.getWrapperField(this, proto.audit.AuditRecord, 1));
};


/**
 * @param {?proto.audit.AuditRecord|undefined} value
 * @return {!proto.audit.SaveAuditResponse} returns this
*/
proto.audit.SaveAuditResponse.prototype.setAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.SaveAuditResponse} returns this
 */
proto.audit.SaveAuditResponse.prototype.clearAudit = function() {
  return this.setAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.SaveAuditResponse.prototype.hasAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditRecords.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRecords.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRecords.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRecords} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRecords.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.audit.AuditRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRecords}
 */
proto.audit.AuditRecords.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRecords;
  return proto.audit.AuditRecords.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRecords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRecords}
 */
proto.audit.AuditRecords.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.AuditRecord;
      reader.readMessage(value,proto.audit.AuditRecord.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRecords.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRecords.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRecords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRecords.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.AuditRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditRecord items = 1;
 * @return {!Array<!proto.audit.AuditRecord>}
 */
proto.audit.AuditRecords.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.audit.AuditRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.AuditRecord, 1));
};


/**
 * @param {!Array<!proto.audit.AuditRecord>} value
 * @return {!proto.audit.AuditRecords} returns this
*/
proto.audit.AuditRecords.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.AuditRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.AuditRecord}
 */
proto.audit.AuditRecords.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.AuditRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRecords} returns this
 */
proto.audit.AuditRecords.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contentId: (f = msg.getContentId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetReportRequest}
 */
proto.audit.GetReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetReportRequest;
  return proto.audit.GetReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetReportRequest}
 */
proto.audit.GetReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.audit.ReportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getContentId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.GetReportRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.GetReportRequest} returns this
*/
proto.audit.GetReportRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetReportRequest} returns this
 */
proto.audit.GetReportRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetReportRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.GetReportRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.GetReportRequest} returns this
*/
proto.audit.GetReportRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetReportRequest} returns this
 */
proto.audit.GetReportRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetReportRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReportType type = 3;
 * @return {!proto.audit.ReportType}
 */
proto.audit.GetReportRequest.prototype.getType = function() {
  return /** @type {!proto.audit.ReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.audit.ReportType} value
 * @return {!proto.audit.GetReportRequest} returns this
 */
proto.audit.GetReportRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue content_id = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.GetReportRequest.prototype.getContentId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.GetReportRequest} returns this
*/
proto.audit.GetReportRequest.prototype.setContentId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetReportRequest} returns this
 */
proto.audit.GetReportRequest.prototype.clearContentId = function() {
  return this.setContentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetReportRequest.prototype.hasContentId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.SaveReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.SaveReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.SaveReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    body: msg.getBody_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.SaveReportRequest}
 */
proto.audit.SaveReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.SaveReportRequest;
  return proto.audit.SaveReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.SaveReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.SaveReportRequest}
 */
proto.audit.SaveReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.audit.ReportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.SaveReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.SaveReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.SaveReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SaveReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.SaveReportRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SaveReportRequest} returns this
 */
proto.audit.SaveReportRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.SaveReportRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SaveReportRequest} returns this
 */
proto.audit.SaveReportRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReportType type = 3;
 * @return {!proto.audit.ReportType}
 */
proto.audit.SaveReportRequest.prototype.getType = function() {
  return /** @type {!proto.audit.ReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.audit.ReportType} value
 * @return {!proto.audit.SaveReportRequest} returns this
 */
proto.audit.SaveReportRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string Mime = 4;
 * @return {string}
 */
proto.audit.SaveReportRequest.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SaveReportRequest} returns this
 */
proto.audit.SaveReportRequest.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes body = 5;
 * @return {!(string|Uint8Array)}
 */
proto.audit.SaveReportRequest.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes body = 5;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.audit.SaveReportRequest.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.audit.SaveReportRequest.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.audit.SaveReportRequest} returns this
 */
proto.audit.SaveReportRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetReportResponse}
 */
proto.audit.GetReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetReportResponse;
  return proto.audit.GetReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetReportResponse}
 */
proto.audit.GetReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uri = 1;
 * @return {string}
 */
proto.audit.GetReportResponse.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetReportResponse} returns this
 */
proto.audit.GetReportResponse.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.CloseAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.CloseAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.CloseAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CloseAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.CloseAuditRequest}
 */
proto.audit.CloseAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.CloseAuditRequest;
  return proto.audit.CloseAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.CloseAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.CloseAuditRequest}
 */
proto.audit.CloseAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.CloseAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.CloseAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.CloseAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CloseAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.CloseAuditRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CloseAuditRequest} returns this
 */
proto.audit.CloseAuditRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditRecord.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isClosed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isInterval: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    complianceFrameworkOptionsList: jspb.Message.toObjectList(msg.getComplianceFrameworkOptionsList(),
    audit_framework_pb.ComplianceFrameworkOption.toObject, includeInstance),
    complianceFrameworksList: jspb.Message.toObjectList(msg.getComplianceFrameworksList(),
    audit_framework_pb.ComplianceFramework.toObject, includeInstance),
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.audit.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRecord}
 */
proto.audit.AuditRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRecord;
  return proto.audit.AuditRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRecord}
 */
proto.audit.AuditRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsClosed(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInterval(value);
      break;
    case 7:
      var value = new audit_framework_pb.ComplianceFrameworkOption;
      reader.readMessage(value,audit_framework_pb.ComplianceFrameworkOption.deserializeBinaryFromReader);
      msg.addComplianceFrameworkOptions(value);
      break;
    case 8:
      var value = new audit_framework_pb.ComplianceFramework;
      reader.readMessage(value,audit_framework_pb.ComplianceFramework.deserializeBinaryFromReader);
      msg.addComplianceFrameworks(value);
      break;
    case 9:
      var value = new proto.audit.Report;
      reader.readMessage(value,proto.audit.Report.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsClosed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsInterval();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getComplianceFrameworkOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      audit_framework_pb.ComplianceFrameworkOption.serializeBinaryToWriter
    );
  }
  f = message.getComplianceFrameworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      audit_framework_pb.ComplianceFramework.serializeBinaryToWriter
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.audit.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.audit.AuditRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.AuditRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRecord.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRecord} returns this
*/
proto.audit.AuditRecord.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRecord.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRecord.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRecord} returns this
*/
proto.audit.AuditRecord.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRecord.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_closed = 5;
 * @return {boolean}
 */
proto.audit.AuditRecord.prototype.getIsClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.setIsClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_interval = 6;
 * @return {boolean}
 */
proto.audit.AuditRecord.prototype.getIsInterval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.setIsInterval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated ComplianceFrameworkOption compliance_framework_options = 7;
 * @return {!Array<!proto.audit.ComplianceFrameworkOption>}
 */
proto.audit.AuditRecord.prototype.getComplianceFrameworkOptionsList = function() {
  return /** @type{!Array<!proto.audit.ComplianceFrameworkOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, audit_framework_pb.ComplianceFrameworkOption, 7));
};


/**
 * @param {!Array<!proto.audit.ComplianceFrameworkOption>} value
 * @return {!proto.audit.AuditRecord} returns this
*/
proto.audit.AuditRecord.prototype.setComplianceFrameworkOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.audit.ComplianceFrameworkOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ComplianceFrameworkOption}
 */
proto.audit.AuditRecord.prototype.addComplianceFrameworkOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.audit.ComplianceFrameworkOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.clearComplianceFrameworkOptionsList = function() {
  return this.setComplianceFrameworkOptionsList([]);
};


/**
 * repeated ComplianceFramework compliance_frameworks = 8;
 * @return {!Array<!proto.audit.ComplianceFramework>}
 */
proto.audit.AuditRecord.prototype.getComplianceFrameworksList = function() {
  return /** @type{!Array<!proto.audit.ComplianceFramework>} */ (
    jspb.Message.getRepeatedWrapperField(this, audit_framework_pb.ComplianceFramework, 8));
};


/**
 * @param {!Array<!proto.audit.ComplianceFramework>} value
 * @return {!proto.audit.AuditRecord} returns this
*/
proto.audit.AuditRecord.prototype.setComplianceFrameworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.audit.ComplianceFramework=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ComplianceFramework}
 */
proto.audit.AuditRecord.prototype.addComplianceFrameworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.audit.ComplianceFramework, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.clearComplianceFrameworksList = function() {
  return this.setComplianceFrameworksList([]);
};


/**
 * repeated Report reports = 9;
 * @return {!Array<!proto.audit.Report>}
 */
proto.audit.AuditRecord.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.audit.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.Report, 9));
};


/**
 * @param {!Array<!proto.audit.Report>} value
 * @return {!proto.audit.AuditRecord} returns this
*/
proto.audit.AuditRecord.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.audit.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.Report}
 */
proto.audit.AuditRecord.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.audit.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRecord} returns this
 */
proto.audit.AuditRecord.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.Report}
 */
proto.audit.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.Report;
  return proto.audit.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.Report}
 */
proto.audit.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.audit.ReportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.audit.Report.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.Report} returns this
 */
proto.audit.Report.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.Report} returns this
 */
proto.audit.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReportType type = 3;
 * @return {!proto.audit.ReportType}
 */
proto.audit.Report.prototype.getType = function() {
  return /** @type {!proto.audit.ReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.audit.ReportType} value
 * @return {!proto.audit.Report} returns this
 */
proto.audit.Report.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.Report.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.Report} returns this
*/
proto.audit.Report.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.Report} returns this
 */
proto.audit.Report.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.Report.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetComplianceFrameworksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetComplianceFrameworksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetComplianceFrameworksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetComplianceFrameworksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    excludeDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetComplianceFrameworksRequest}
 */
proto.audit.GetComplianceFrameworksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetComplianceFrameworksRequest;
  return proto.audit.GetComplianceFrameworksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetComplianceFrameworksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetComplianceFrameworksRequest}
 */
proto.audit.GetComplianceFrameworksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetComplianceFrameworksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetComplianceFrameworksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetComplianceFrameworksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetComplianceFrameworksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExcludeDisabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool exclude_disabled = 1;
 * @return {boolean}
 */
proto.audit.GetComplianceFrameworksRequest.prototype.getExcludeDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.GetComplianceFrameworksRequest} returns this
 */
proto.audit.GetComplianceFrameworksRequest.prototype.setExcludeDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetHasComplianceFrameworksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetHasComplianceFrameworksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetHasComplianceFrameworksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetHasComplianceFrameworksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasenabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetHasComplianceFrameworksResponse}
 */
proto.audit.GetHasComplianceFrameworksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetHasComplianceFrameworksResponse;
  return proto.audit.GetHasComplianceFrameworksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetHasComplianceFrameworksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetHasComplianceFrameworksResponse}
 */
proto.audit.GetHasComplianceFrameworksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetHasComplianceFrameworksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetHasComplianceFrameworksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetHasComplianceFrameworksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetHasComplianceFrameworksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasenabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool hasEnabled = 1;
 * @return {boolean}
 */
proto.audit.GetHasComplianceFrameworksResponse.prototype.getHasenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.GetHasComplianceFrameworksResponse} returns this
 */
proto.audit.GetHasComplianceFrameworksResponse.prototype.setHasenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetCriteriaControlMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetCriteriaControlMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetCriteriaControlMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetCriteriaControlMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetCriteriaControlMappingsRequest}
 */
proto.audit.GetCriteriaControlMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetCriteriaControlMappingsRequest;
  return proto.audit.GetCriteriaControlMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetCriteriaControlMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetCriteriaControlMappingsRequest}
 */
proto.audit.GetCriteriaControlMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetCriteriaControlMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetCriteriaControlMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetCriteriaControlMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetCriteriaControlMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.GetCriteriaControlMappingsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.GetCriteriaControlMappingsRequest} returns this
*/
proto.audit.GetCriteriaControlMappingsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetCriteriaControlMappingsRequest} returns this
 */
proto.audit.GetCriteriaControlMappingsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetCriteriaControlMappingsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.CriteriaControlMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.CriteriaControlMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.CriteriaControlMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CriteriaControlMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    criterion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objective: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlModelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frameworkName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.CriteriaControlMapping}
 */
proto.audit.CriteriaControlMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.CriteriaControlMapping;
  return proto.audit.CriteriaControlMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.CriteriaControlMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.CriteriaControlMapping}
 */
proto.audit.CriteriaControlMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriterion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlModelId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrameworkName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.CriteriaControlMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.CriteriaControlMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.CriteriaControlMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CriteriaControlMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriterion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrameworkName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string criterion = 1;
 * @return {string}
 */
proto.audit.CriteriaControlMapping.prototype.getCriterion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CriteriaControlMapping} returns this
 */
proto.audit.CriteriaControlMapping.prototype.setCriterion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string control_name = 2;
 * @return {string}
 */
proto.audit.CriteriaControlMapping.prototype.getControlName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CriteriaControlMapping} returns this
 */
proto.audit.CriteriaControlMapping.prototype.setControlName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string objective = 3;
 * @return {string}
 */
proto.audit.CriteriaControlMapping.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CriteriaControlMapping} returns this
 */
proto.audit.CriteriaControlMapping.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string control_model_id = 5;
 * @return {string}
 */
proto.audit.CriteriaControlMapping.prototype.getControlModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CriteriaControlMapping} returns this
 */
proto.audit.CriteriaControlMapping.prototype.setControlModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string framework_name = 6;
 * @return {string}
 */
proto.audit.CriteriaControlMapping.prototype.getFrameworkName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CriteriaControlMapping} returns this
 */
proto.audit.CriteriaControlMapping.prototype.setFrameworkName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetCriteriaControlMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetCriteriaControlMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetCriteriaControlMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetCriteriaControlMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetCriteriaControlMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mappingsList: jspb.Message.toObjectList(msg.getMappingsList(),
    proto.audit.CriteriaControlMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetCriteriaControlMappingsResponse}
 */
proto.audit.GetCriteriaControlMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetCriteriaControlMappingsResponse;
  return proto.audit.GetCriteriaControlMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetCriteriaControlMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetCriteriaControlMappingsResponse}
 */
proto.audit.GetCriteriaControlMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.CriteriaControlMapping;
      reader.readMessage(value,proto.audit.CriteriaControlMapping.deserializeBinaryFromReader);
      msg.addMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetCriteriaControlMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetCriteriaControlMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetCriteriaControlMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetCriteriaControlMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.CriteriaControlMapping.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CriteriaControlMapping mappings = 1;
 * @return {!Array<!proto.audit.CriteriaControlMapping>}
 */
proto.audit.GetCriteriaControlMappingsResponse.prototype.getMappingsList = function() {
  return /** @type{!Array<!proto.audit.CriteriaControlMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.CriteriaControlMapping, 1));
};


/**
 * @param {!Array<!proto.audit.CriteriaControlMapping>} value
 * @return {!proto.audit.GetCriteriaControlMappingsResponse} returns this
*/
proto.audit.GetCriteriaControlMappingsResponse.prototype.setMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.CriteriaControlMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.CriteriaControlMapping}
 */
proto.audit.GetCriteriaControlMappingsResponse.prototype.addMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.CriteriaControlMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetCriteriaControlMappingsResponse} returns this
 */
proto.audit.GetCriteriaControlMappingsResponse.prototype.clearMappingsList = function() {
  return this.setMappingsList([]);
};


/**
 * @enum {number}
 */
proto.audit.ReportType = {
  CLOSED_AUDIT_FINAL_PAPERS: 0,
  WORK_PAPERS: 1
};

goog.object.extend(exports, proto.audit);
