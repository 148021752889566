import React, { useEffect, useState } from 'react'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import palette from 'src/designSystem/variables/palette'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { P } from '../Text/Text.styles'
import { TextButton } from '../Buttons/TextButton'
import {
  ExpandPDFRow,
  PreviewDiv,
  StyledEvidenceIframe,
  StyledEvidenceImage,
  StyledImagePreview,
  StyledPreviewModal,
  StyledPreviewModalIframe,
  StyledPreviewModalImage,
} from './DocumentViewer.styles'

export const ImagePreview = ({ file }: { file: File }): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [link, setLink] = useState<string | null>(null)
  const FileTypeIcon = useFileTypeIcon({ mime: file.type })

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  const isImage = file.type.startsWith('image/')
  const isPdf = file.type === 'application/pdf'

  useEffect(() => {
    setLink(URL.createObjectURL(file))
  }, [file])

  if ((!isImage && !isPdf) || !link) {
    return <></>
  }

  const hasNewEvidence = IsGatekeeperEnabled(GatekeeperFlag.NEW_EVIDENCE_TABLE)

  return isImage ? (
    <>
      {hasNewEvidence ? (
        <StyledEvidenceImage
          src={link as string}
          alt={file.name}
          onClick={toggleModal}
        />
      ) : (
        <PreviewDiv>
          <StyledImagePreview src={link} alt="Preview" onClick={toggleModal} />
        </PreviewDiv>
      )}
      {isModalOpen && (
        <StyledPreviewModal onClick={toggleModal}>
          <StyledPreviewModalImage
            src={link as string}
            alt="Expanded Preview"
          />
          <CloseButton />
        </StyledPreviewModal>
      )}
    </>
  ) : (
    <>
      {hasNewEvidence ? (
        <>
          <ExpandPDFRow>
            <TextButton onClick={toggleModal}>Expand</TextButton>
          </ExpandPDFRow>
          <StyledEvidenceIframe src={link as string} typeof="application/pdf" />
        </>
      ) : (
        <PreviewDiv onClick={toggleModal}>
          <FileTypeIcon width="120px" height="120px" />
          <p>View PDF</p>
        </PreviewDiv>
      )}
      {isModalOpen && (
        <StyledPreviewModal onClick={toggleModal}>
          <StyledPreviewModalIframe
            src={link as string}
            typeof="application/pdf"
          />
          <CloseButton />
        </StyledPreviewModal>
      )}
    </>
  )
}

const CloseButton = (): JSX.Element => (
  <P $color={palette.neutral['50']} $isUnderline>
    close
  </P>
)
