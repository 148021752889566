import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AlignedShowPageItemStatsValue,
  ShowPageContainer,
  ShowPageItemStatsLabel,
  ShowPageSectionContainer,
} from 'src/components/PageLayout/ShowPage'
import palette from 'src/designSystem/variables/palette'
import { $truncate } from 'src/globalStyles'
import styled from 'styled-components/macro'
import { StandardButton } from 'src/components/Reusable/Buttons'
import { border, compose } from 'styled-system'
import { FileInfoContainer } from '../CompanyInfoPage/modals/CompanyInfoModals.styles'

export const EvidenceShowPageContainer = styled(ShowPageContainer)`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
export const EvidenceDescriptionTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`

export const EvidenceDescription = styled.div<{ $truncated?: boolean }>`
  padding: 0;
  .markup-section {
    padding: 0;
    ${({ $truncated }) =>
      $truncated &&
      `p {
      ${$truncate}
    }`}
    margin: 0;
  }
  margin-bottom: 16px;
`

export const EvidenceTimestamp = styled.p`
  font-size: 14px;
  line-height: 150%;
  color: ${palette.neutral[600]};
`

export const EvidenceShowTabBody = styled.div`
  ${compose(border)}
  flex: 1 1 auto;
  padding: 8px;
  overflow-y: auto;
  border: 1px solid ${palette.neutral['100']};
`

export const EvidenceShowPageIcon = styled(FontAwesomeIcon)`
  color: ${palette.neutral[300]};
  font-weight: 900;
  height: 16px;
  width: 16px;
`

export const PropertiesContainer = styled.div`
  border: 1px solid ${palette.neutral[100]};
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`

export const PropertiesGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 24px;
  width: 100%;
`

export const PropertiesRow = styled.div`
  &:nth-child(even) {
    background-color: ${palette.neutral[30]};
  }
  padding: 16px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: center;
`

export const PropertiesLabel = styled(ShowPageItemStatsLabel)`
  grid-column: 1 / 2;
`

export const PropertiesValue = styled(AlignedShowPageItemStatsValue)`
  grid-column: 2 / -1;
  input,
  span {
    width: max-content;
  }
`

export const EvidenceDropdownContainer = styled.div`
  width: max-content;
  button {
    padding: 12px;
  }
  .dropdown-toggle {
    display: flex;
    div {
      padding: 0 8px;
    }
  }
  margin-top: 24px;
`

export const EvidenceFileInfoContainer = styled(FileInfoContainer)`
  width: max-content;
  border-radius: 8px;
  margin-top: 16px;
  height: auto;
  padding: 16px 32px;
  svg {
    width: 120px;
    height: 120px;
  }
`

export const EvidenceFileName = styled.p`
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: ${palette.neutral[600]};
`

export const ManualEvidenceContainer = styled(ShowPageSectionContainer)`
  h6 {
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
`

export const RelevantDateButton = styled(StandardButton)`
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  border-color: ${palette.neutral[100]};
  border-radius: 4px;
  padding: 8px 24px;
  width: 100%;
  max-width: 420px;
`
